import React from 'react';

import Spinner from '@glass/web/components/Loading/Spinner';
import makeStyles from '@glass/web/modules/theme/makeStyles';

export const DEFAULT_SPINNER_MIN_HEIGHT = 420;

type SpinnerWithContainerProps = {
  minHeight?: string | number;
  width?: string | number;
  height?: string | number;
  className?: string;
  children?: React.ReactNode;
};

const useStyles = makeStyles<SpinnerWithContainerProps>()((_, { width, height, minHeight }) => ({
  container: {
    position: 'relative',
    width,
    height,
    minHeight,
  },
}));

function SpinnerWithContainer({
  children = <Spinner />,
  minHeight = DEFAULT_SPINNER_MIN_HEIGHT,
  height,
  width = '100%',
  className,
}: SpinnerWithContainerProps) {
  const { classes, cx } = useStyles({
    width,
    height,
    minHeight,
  });
  return <div className={cx(className, classes.container)}>{children}</div>;
}

export default React.memo(SpinnerWithContainer);
