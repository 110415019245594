import { VERCEL_PROTECTION_BYPASS_QUERY } from '@glass/common/modules/pages/queryParams';
import createUrl from '@glass/common/modules/url/createUrl';
import { createObjectSlug, reFileExtension } from '@glass/common/modules/utils/objectSlug';
import { currentOrigin } from '@glass/env/modules/origins/constants';

import { QA_PREFIX, SEO_TITLE_PREFIX_ROCKET } from '@glass/shared/modules/images/constants';

const CLOUDFLARE_IMAGE_PREFIX = '/cdn-cgi/image';
const CLOUDFLARE_SETTING_DELIMITER = ',';

export const WIDTH_KEY = 'w';
export const HEIGHT_KEY = 'h';
export const FIT_KEY = 'fit';
export const QUALITY_KEY = 'q';

const defaultCloudflareOptions = {
  f: 'auto',
};

const generateImageSrc = ({
  seoTitle,
  seoTitlePrefix = SEO_TITLE_PREFIX_ROCKET,
  path,
  width,
  height,
  cloudflareCdn = true,
  external,
  fit,
  quality = 80,
  origin = process.env.NEXT_PUBLIC_IMAGE_ORIGIN || currentOrigin,
}) => {
  if (!path) {
    return '';
  }

  let finalPath = path[0] === '/' ? path : `/${path}`;
  let finalQuery = null;

  if (!external) {
    if (origin?.includes?.('://qa.')) {
      finalPath = `/${QA_PREFIX}${finalPath}`;
    }
    // rocket resume cloudflare worker
    if (seoTitle) {
      const newEnding = finalPath.replace(reFileExtension, `/${seoTitle}.$1`);
      finalPath = `${seoTitlePrefix}${newEnding}`;
    }

    finalQuery = {
      ...finalQuery,
      [VERCEL_PROTECTION_BYPASS_QUERY]: process.env.VERCEL_AUTOMATION_BYPASS_SECRET,
    };
  }

  // cloudflare image cdn
  if (cloudflareCdn) {
    let cfFit;

    if (height) {
      cfFit = 'cover';
    } else if (width) {
      cfFit = 'scale-down';
    }

    const responsiveImageSlug = createObjectSlug(
      {
        ...defaultCloudflareOptions,
        [FIT_KEY]: fit || cfFit,
        [HEIGHT_KEY]: height,
        [WIDTH_KEY]: width,
        [QUALITY_KEY]: quality,
      },
      { settingsDelimiter: CLOUDFLARE_SETTING_DELIMITER },
    );

    finalPath = `${CLOUDFLARE_IMAGE_PREFIX}/${responsiveImageSlug}${finalPath}`;
  }

  return createUrl({
    origin,
    pathname: finalPath,
    query: finalQuery,
  });
};

export default generateImageSrc;
