const re = /\$(\w+)\((.*?)\)/gi;

const generateSizes = (theme, str) =>
  str?.replace(re, (match, fn, value) => {
    if (typeof theme.breakpoints[fn] === 'function') {
      return theme.breakpoints[fn].apply(null, value.split(',')).replace('@media ', '');
    }
    return '';
  });

export default generateSizes;
