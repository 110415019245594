import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';
import { currentOrigin } from '@glass/env/modules/origins/constants';

import JsonLDScript from '@glass/shared/components/Head/JsonLDScript';
import useExtendTextData from '@glass/shared/modules/content/useExtendTextData';

function BreadcrumbStructuredData({ items, title }) {
  const { extendText } = useExtendTextData();

  const schema = useMemo(() => {
    const itemElements = [...(items || [])];

    if (title) {
      itemElements.push({ text: extendText(title) });
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: itemElements.map(({ text, href }, idx) =>
        removeEmptyKeys({
          '@type': 'ListItem',
          position: idx + 1,
          name: extendText(text),
          item: href ? `${currentOrigin}${href}` : undefined,
        }),
      ),
    };
  }, [extendText, items, title]);

  if (!items?.length) {
    return null;
  }

  return <JsonLDScript schema={schema} />;
}

BreadcrumbStructuredData.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      text: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string,
};

export default React.memo(BreadcrumbStructuredData);
