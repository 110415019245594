import PropTypes from 'prop-types';
import React from 'react';

import useCenterLargeStyles from '@glass/web/components/Typography/useCenterLargeStyles';
import MuiBreadcrumbs from '@glass/web/components/base/Breadcrumbs';
import NavigateNext from '@glass/web/components/base/icons/NavigateNext';
import { CENTER, CENTER_LARGE } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import BreadcrumbItem from '@glass/shared/components/Breadcrumbs/BreadcrumbItem';
import Link from '@glass/shared/components/Link/Link';
import { BREADCRUMB_ITEM_TYPE } from '@glass/shared/modules/jsonLd/constants/breadcrumbs';

const useStyles = makeStyles()({
  isPageTitle: {
    '& > ol > li:last-child': {
      width: '100%',
    },
  },
});

function Breadcrumbs({
  items,
  variant,
  trailingSeparator,
  disableStructuredData,
  linkComponent,
  gutterBottom,
  lastVariant,
  isPageTitle,
  align,
}) {
  const { classes, cx } = useStyles();
  const { classes: centerLargeClasses } = useCenterLargeStyles();

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      className={cx(
        isPageTitle ? classes.isPageTitle : null,
        align === CENTER ? centerLargeClasses.center : null,
        align === CENTER_LARGE ? centerLargeClasses.centerLarge : null,
      )}
      color="inherit"
      itemScope={!disableStructuredData}
      itemType={!disableStructuredData ? BREADCRUMB_ITEM_TYPE : undefined}
      separator={<NavigateNext fontSize="small" />}
      typeof="BreadcrumbList"
    >
      {items?.map(({ text, href, component: itemComponent }, idx) => {
        const last = !trailingSeparator && idx === items.length - 1;
        return (
          <BreadcrumbItem
            key={href || text}
            className={cx(isPageTitle && last && classes.lastPageTitle)}
            component={itemComponent || linkComponent}
            containerComponent={last ? 'div' : undefined}
            disableStructuredData={disableStructuredData}
            gutterBottom={gutterBottom}
            href={href}
            idx={idx}
            last={last}
            text={text}
            variant={lastVariant && last ? lastVariant : variant}
          />
        );
      })}
      {trailingSeparator ? <span /> : null}
    </MuiBreadcrumbs>
  );
}

Breadcrumbs.propTypes = {
  align: PropTypes.string,
  disableStructuredData: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  isPageTitle: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.elementType,
      href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      text: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  lastVariant: PropTypes.string,
  linkComponent: PropTypes.elementType,
  trailingSeparator: PropTypes.bool,
  variant: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  linkComponent: Link,
};

export default React.memo(Breadcrumbs);
