import PropTypes from 'prop-types';
import React from 'react';

import { currentOrigin } from '@glass/env/modules/origins/constants';
import Typography from '@glass/web/components/base/Typography';

import useExtendText from '@glass/shared/modules/content/useExtendText';
import { LIST_ITEM_TYPE } from '@glass/shared/modules/jsonLd/constants/breadcrumbs';
import {
  ITEM_PROP_ITEM,
  ITEM_PROP_NAME,
  ITEM_PROP_POSITION,
} from '@glass/shared/modules/jsonLd/constants/common';

function BreadcrumbItem({
  text,
  href,
  component,
  disableStructuredData,
  last,
  variant,
  gutterBottom,
  idx,
}) {
  const isLink = !!(!last && (component || href));
  const extendedText = useExtendText(text);

  return (
    <span
      itemProp={!disableStructuredData ? 'itemListElement' : undefined}
      itemScope={!disableStructuredData}
      itemType={!disableStructuredData ? LIST_ITEM_TYPE : undefined}
    >
      <Typography
        key={text}
        color="inherit"
        component={isLink ? component : undefined}
        gutterBottom={gutterBottom}
        href={isLink ? href : undefined}
        itemID={!disableStructuredData && href ? `${currentOrigin}${href}` : undefined}
        itemProp={!disableStructuredData && isLink ? ITEM_PROP_ITEM : undefined}
        variant={variant}
      >
        <span itemProp={!disableStructuredData ? ITEM_PROP_NAME : undefined}>{extendedText}</span>
        {!disableStructuredData ? <meta content={idx + 1} itemProp={ITEM_PROP_POSITION} /> : null}
      </Typography>
    </span>
  );
}

BreadcrumbItem.propTypes = {
  component: PropTypes.elementType,
  disableStructuredData: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  idx: PropTypes.number,
  last: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.string,
};

BreadcrumbItem.defaultProps = {
  variant: 'h4',
};

export default React.memo(BreadcrumbItem);
