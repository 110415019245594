import React, { useMemo } from 'react';

import Fade from '@glass/web/components/base/Fade';

type DelayFadeProps = {
  in?: boolean;
  appear?: boolean;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  delayMs?: number;
  children: React.ReactElement<any, any>;
};

function DelayFade({
  in: inProp,
  appear,
  delayMs = 400,
  children,
  unmountOnExit,
  mountOnEnter,
}: DelayFadeProps) {
  const style = useMemo(
    () => ({
      transitionDelay: inProp ? `${delayMs || 0}ms` : '0ms',
    }),
    [delayMs, inProp],
  );

  return (
    <Fade
      in={inProp}
      appear={appear}
      style={style}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
    >
      {children}
    </Fade>
  );
}

export default React.memo(DelayFade);
