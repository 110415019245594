import { useMemo } from 'react';

import getImageHeight from '@glass/shared/components/Image/helpers/getImageHeight';
import generateImageSrc from '@glass/shared/modules/images/generateImageSrc';

const useSrc = ({
  width,
  seoTitle,
  path,
  cloudflareCdn = true,
  origin,
  external = false,
  aspectRatio,
  height,
  fit,
  quality,
} = {}) =>
  useMemo(
    () =>
      generateImageSrc({
        width,
        height: height || getImageHeight(width, aspectRatio),
        seoTitle,
        path,
        origin,
        cloudflareCdn,
        external,
        fit,
        quality,
      }),
    [width, height, aspectRatio, seoTitle, path, origin, cloudflareCdn, external, fit, quality],
  );

export default useSrc;
