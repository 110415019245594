export const PDF_FORMAT = 'pdf';
export const JPEG_FORMAT = 'jpeg';
export const PNG_FORMAT = 'png';

export const RESUME_TYPE = 'resumes';
export const OG_IMAGE_TYPE = 'og-images';

export const SEO_TITLE_PREFIX_ROCKET = '/rocket';
export const SEO_TITLE_PREFIX_IMAGES = '/images';
export const RESUMES_PREFIX = RESUME_TYPE;
export const OG_IMAGE_PREFIX = OG_IMAGE_TYPE;

export const QA_PREFIX = '_qa';
