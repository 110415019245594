import React from 'react';

import DelayFade from '@glass/web/components/Loading/DelayFade';
import CircularProgress from '@glass/web/components/base/CircularProgress';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import positionAbsoluteCenter from '@glass/web/modules/theme/styles/positionAbsoluteCenter';

type SpinnerProps = {
  loading?: boolean;
  size?: number;
};

const useStyles = makeStyles()({
  root: {
    ...positionAbsoluteCenter,
  },
});

function Spinner({ loading = true, size }: SpinnerProps) {
  const { classes } = useStyles();
  return (
    <DelayFade in={loading} appear={loading}>
      <CircularProgress size={size} className={classes.root} />
    </DelayFade>
  );
}

export default React.memo(Spinner);
