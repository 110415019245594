import { CSSObject } from '@emotion/react';

const style: CSSObject = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
};

export default style;
