// todo: remove lodash on client
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { COMMA_SPACE_DELIMITER } from '@glass/common/modules/strings/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import useTheme from '@glass/web/modules/theme/useTheme';

import getImageHeight from '@glass/shared/components/Image/helpers/getImageHeight';
import useSrc from '@glass/shared/components/Image/helpers/useSrc';
import { HEIGHT_KEY, WIDTH_KEY } from '@glass/shared/modules/images/generateImageSrc';
import generateSizes from '@glass/shared/modules/images/generateSizes';

const useSizes = ({ sizes, responsive }) => {
  const theme = useTheme();
  return useMemo(() => (responsive ? generateSizes(theme, sizes) : ''), [theme, sizes, responsive]);
};

const reSrcSetWidth = /(\/cdn-cgi\/image\/.*?\W)(w=\d+)(.*?\/.*)/;
const reSrcSetHeight = /(\/cdn-cgi\/image\/.*?\W)(h=\d+)(.*)/;

const CF_WIDTH_MULTIPLE = 20;
const getCfWidthSize = ({ downscale, width }) =>
  CF_WIDTH_MULTIPLE * Math.floor((downscale * width + CF_WIDTH_MULTIPLE / 2) / CF_WIDTH_MULTIPLE);

const useSrcSet = ({
  src,
  maxWidth: maxWidthProp,
  widthStep,
  responsive,
  aspectRatio,
  downscale,
}) => {
  return useMemo(() => {
    if (!responsive) {
      return '';
    }

    const maxWidth = Math.min(maxWidthProp, 1920);
    const includesHeight = typeof aspectRatio === 'number' && src.match(/\Wh=\d+/);

    const srcSets = [];
    let width = widthStep;
    while (width <= maxWidth) {
      let srcSet = `${src.replace(
        reSrcSetWidth,
        `$1${WIDTH_KEY}=${getCfWidthSize({ width, downscale })}$3 ${width}w`,
      )}`;
      if (includesHeight) {
        const height = getImageHeight(width, aspectRatio);
        srcSet = `${srcSet.replace(reSrcSetHeight, `$1${HEIGHT_KEY}=${height}$3`)}`;
      }
      srcSets.push(srcSet);
      width += widthStep;
    }
    return srcSets.join(COMMA_SPACE_DELIMITER);
  }, [maxWidthProp, responsive, widthStep, aspectRatio, src, downscale]);
};

const useAlt = ({ alt, seoTitle }) => {
  return useMemo(() => {
    return alt || startCase(seoTitle)?.toLowerCase();
  }, [alt, seoTitle]);
};

const useLoad = ({ load }) => {
  const [hasLoaded, setHasLoaded] = useState(load);
  useEffect(() => {
    if (load && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [load, hasLoaded]);
  return load || hasLoaded;
};

const useStyles = makeStyles()((theme, { aspectRatio }) => ({
  root: {
    aspectRatio,
  },
}));

function ResponsiveImage({
  className,
  alt,
  seoTitle,
  sizes,
  path,
  maxWidth,
  widthStep,
  defaultWidth,
  responsive,
  load,
  external,
  aspectRatio,
  cloudflareCdn,
  origin,
  downscale,
  quality,
  width,
  height,
  fetchPriority,
}) {
  const imageRef = useRef(null);
  const imgLoad = useLoad({ load });
  const imgSizes = useSizes({ sizes, responsive });
  const imgSrc = useSrc({
    path,
    width: Math.floor(downscale * (typeof width === 'number' ? width : defaultWidth)),
    seoTitle,
    cloudflareCdn,
    external,
    aspectRatio,
    origin,
    quality,
    downscale,
  });
  const imgSrcSet = useSrcSet({
    src: imgSrc,
    maxWidth,
    widthStep,
    responsive,
    aspectRatio,
    downscale,
  });
  const imgAlt = useAlt({ alt, seoTitle });
  const { classes, cx } = useStyles({ aspectRatio, width });

  if (imgLoad) {
    return (
      <img
        ref={imageRef}
        alt={imgAlt}
        className={cx(className, classes.root)}
        fetchpriority={fetchPriority}
        height={height}
        sizes={imgSizes}
        src={imgSrc}
        srcSet={imgSrcSet}
        width={width}
      />
    );
  }

  return <img alt={imgAlt} className={cx(className, classes.root)} height={height} width={width} />;
}

ResponsiveImage.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  cloudflareCdn: PropTypes.bool,
  defaultWidth: PropTypes.number,
  downscale: PropTypes.number,
  external: PropTypes.bool,
  fetchPriority: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  load: PropTypes.bool,
  maxWidth: PropTypes.number,
  origin: PropTypes.string,
  path: PropTypes.string.isRequired,
  quality: PropTypes.number,
  responsive: PropTypes.bool,
  seoTitle: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widthStep: PropTypes.number,
};

ResponsiveImage.defaultProps = {
  maxWidth: 1920,
  widthStep: 80,
  defaultWidth: 800,
  cloudflareCdn: true,
  responsive: true,
  load: true,
  external: false,
  aspectRatio: null,
  downscale: 1,
  width: '100%',
};

export default React.memo(ResponsiveImage);
