/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';

function JsonLDScript({ schema }) {
  if (!schema) {
    return null;
  }

  // todo: convert to next/script
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(schema),
      }}
      type="application/ld+json"
    />
  );
}

JsonLDScript.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
};

export default JsonLDScript;
