import PropTypes from 'prop-types';
import React from 'react';

import makeStyles from '@glass/web/modules/theme/makeStyles';

const useStyles = makeStyles()((theme, { width, height }) => ({
  root: {
    height: 0,
    overflow: 'hidden',
    paddingTop: `${(100 * height) / width}%`,
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

function AspectRatio({ className, children, allowOverflow, ...otherProps }) {
  const { classes } = useStyles(otherProps);
  return (
    <div className={classes.root}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
}

AspectRatio.propTypes = {
  allowOverflow: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

AspectRatio.defaultProps = {
  allowOverflow: false,
  width: 1,
  height: 1,
};

export default React.memo(AspectRatio);
